import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageSize: 20,
    resources: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
