import axios from "axios";
import router from "@/router";
const baseUrl = "/mmis";
const request = (options = {}) => {
  // 在这里可以对请求头进行一些设置
  // 例如：
  options.header = {
    "content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + options.url || "",
      method: options.type || "POST",
      data: options.data || {},
      dataType: "json",
      header: options.header || {},
    })
      .then((res) => {
        let result = res.data;
        switch (result.success) {
          case "No Login":
            router.push({
              path: "/login",
            });
            break;
          case "No Permission":
            router.push({
              path: "/error",
            });
            break;
          default:
            resolve(result);
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error);
        // router.push({
        //   path: "/error",
        // });
      });
  });
};

const get = (url, data, options = {}) => {
  options.type = "GET";
  options.data = data;
  options.url = url;
  return request(options);
};

const post = (url, data, options = {}) => {
  options.type = "POST";
  options.data = data;
  options.url = url;
  return request(options);
};

export default {
  request,
  get,
  post,
};
