import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)
const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      auth: true,
      title: 'Flexy Eyewear'
    },
    component: _import('index/index')
  },
  {
    path: '/error',
    name: 'Error',
    meta: {
      auth: true,
      title: '发生错误'
    },
    component: _import('index/error/index')
  }
]

const router = new VueRouter({
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
// router.beforeEach(async (to, from, next) => {
//   next()
// })

// router.afterEach(to => {})
export default router
