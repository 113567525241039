import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from "./api/http";
import Cube from 'cube-ui'
Vue.use(Cube)
Vue.config.productionTip = false;
Vue.prototype.$http = request;
import "amfe-flexible";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
