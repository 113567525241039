<template>
  <div id="app">    
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  async mounted () {
   },
};
</script>

